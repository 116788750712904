import { Link } from "gatsby"
import React from "react"

const WorkLayout = ({ children, title, slides, prev, next }) => (
    <section className="portfolio">
        <div className="nav">
            <Link to={prev.url} className="link-prev" title={prev.title}>
                <svg className="icon icon-arrow-left" viewBox="0 0 32 32">
                    <use xlinkHref="#icon-arrow-left"></use>
                </svg>
            </Link>
            <Link to={next.url} className="link-next" title={next.title}>
                <svg className="icon icon-arrow-right" viewBox="0 0 32 32">
                    <use xlinkHref="#icon-arrow-right"></use>
                </svg>
            </Link>
        </div>
        <article className="clearfix">
            <h2>{title}</h2>
            <ul className="slides list-unstyled">
                {slides.map(slide => (
                    <li key={slide}>
                        <a href={slide} className="link-img">
                            <img src={slide} alt=""/>
                        </a>
                    </li>
                ))}
            </ul>
            <div className="description">
                {children}
            </div>
        </article>
    </section>
);

export default WorkLayout
